import React, { useState, useEffect } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import ReactGA from 'react-ga4';
// import { ReactInternetSpeedMeter } from 'react-internet-meter';
// import 'react-internet-meter/dist/index.css';
import ReactPixel from 'react-facebook-pixel';

// hooks
import useResponsive from '../../hooks/useResponsive';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
// config
import { HEADER, NAVBAR, S3_ASSETS_URL } from '../../config';
// redux
import { useDispatch, useSelector } from '../../redux/store';

//
import DashboardHeader from './header';
import NavbarVertical from './navbar/NavbarVertical';

import MobileNav from './navbar/MobileNav';
import {
  getGameCategsAndCompanies,
  getPromotions,
  getSiteSettings,
  getUserMemberBalance,
  getProfile,
} from '../../redux/slices/lookup';
import CustomModal from '../../components/CustomModal';
import { closeChangePwd, closeForgotPwd, closeLogin, closeRegister, openRegister } from '../../redux/slices/authPages';
import Login from '../../pages/auth/Login';
import Register from '../../pages/auth/Register';
import ForgotPassword from '../../pages/auth/ForgotPassword';

import conObj from '../../utils/connection-assistant';
import ChangePassword from '../../pages/auth/ChangePassword';
import useAuth from '../../hooks/useAuth';
import { usePageVisibility } from '../../hooks/usePageVisibility';
import DepositWithdrawDialog from './navbar/DepositWithdrawDialog';
import WithdrawFormDialog from './navbar/withdraw/WithdrawFormDialog';
import { capitalizeString } from '../../utils/formatString';
import useLocales from '../../hooks/useLocales';
import { closeLoadingScreen, openLoadingScreen } from '../../redux/slices/settings';

// ----------------------------------------------------------------------

const MainStyle = styled('main', {
  shouldForwardProp: (prop) => prop !== 'collapseClick',
})(({ collapseClick, theme }) => ({
  flexGrow: 1,
  paddingTop: 24,
  // paddingBottom: 24,
  paddingLeft: 10,
  paddingRight: 10,
  position: 'relative',
  overflow: 'hidden',
  [theme.breakpoints.up('md')]: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    // paddingBottom: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH})`,
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    }),
    // marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
    // ...(collapseClick && {
    //   marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
    // }),
  },
}));

function updatePos(elem, width, widgetState) {
  console.log(`widgetState = ${widgetState}`);
  if (width < 600 && widgetState === 'widgetClose') {
    elem.style.setProperty('bottom', '25vw', 'important');
    elem.style.setProperty('right', '2vw', 'important');
    elem.style.opacity = 1;
  } else if (width < 600 && widgetState === 'widgetOpen') {
    elem.style.setProperty('bottom', '0px', 'important');
    elem.style.setProperty('right', '0px', 'important');
    elem.style.opacity = 1;
  } else if (width < 600) {
    elem.style.setProperty('bottom', '25vw', 'important');
    elem.style.setProperty('right', '2vw', 'important');
    elem.style.opacity = 1;
  } else {
    elem.style.setProperty('bottom', '25px', 'important');
    elem.style.setProperty('right', '25px', 'important');
    elem.style.opacity = 1;
  }
}

// ----------------------------------------------------------------------

const DashboardLayout = () => {
  const { isAuthenticated } = useAuth();

  const { translate } = useLocales();

  const { paymentMethod } = useParams();

  const { collapseClick, isCollapse } = useCollapseDrawer();

  const { isOpenTransactionsDialog } = useSelector((x) => x.transactionsDialog);

  const { isAddMemberBankDialog } = useSelector((x) => x.addMemberBankDialog);

  const { siteSettings } = useSelector((x) => x.lookup);

  const { enqueueSnackbar } = useSnackbar();
  const { isOpenLogin, isOpenRegister, isOpenForgotPwd, isOpenChangePwd } = useSelector((x) => x.authPages);

  const dispatch = useDispatch();

  const isSm = useResponsive('down', 'md');

  const [open, setOpen] = useState(false);

  const [element, setElement] = useState(null);

  const isVisible = usePageVisibility();

  const [wifiSpeed, setWifiSpeed] = useState(0);

  const navigate = useNavigate();

  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const updateDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };
  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  const checkPaymentStatus = async () => {
    /**
     * handle online pay status
     */
    const orderId = localStorage.getItem('orderId');

    if (orderId && paymentMethod) {
      try {
        const res = await conObj.post(`${paymentMethod}/check_status/${orderId}.json`);
        if (res.status === 200) {
          if (res.data?.data?.status === 1) {
            const _paymentStatus = res.data?.data?.transactionStatus;
            let snackbarVariant = 'error';
            const message = `${capitalizeString(paymentMethod)} Deposit: Order ID ${orderId} ${_paymentStatus}!`;

            switch (_paymentStatus) {
              case 'approved':
                snackbarVariant = 'success';
                break;
              case 'pending':
                snackbarVariant = 'warning';
                break;

              default:
                break;
            }

            navigate('/'); // Navigate to home to clear order id

            enqueueSnackbar(message, { variant: snackbarVariant });

            if (_paymentStatus === 'draft') {
              enqueueSnackbar(translate('payment_draft'), { variant: 'warning' });
            }

            // clear orderId
            localStorage.removeItem('orderId');
          } else {
            enqueueSnackbar(res.data?.data?.msg || `Ooops, Something went wrong!`, { variant: 'error' });
          }
        }
      } catch (err) {
        // clear orderId
        localStorage.removeItem('orderId');
        console.log(`Error: ${err.message}`);
      }
    }
  };

  useEffect(() => {
    checkPaymentStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentMethod]);

  useEffect(() => {
    const promises = [getGameCategsAndCompanies(), getSiteSettings(), getPromotions()];

    dispatch(openLoadingScreen());
    Promise.all(promises).finally(() => dispatch(closeLoadingScreen()));
    // const { search } = window.location;
    // const params = new URLSearchParams(search);
    // const referralCode = params.get('aff');

    // if (referralCode && !isAuthenticated) {
    //   setTimeout(() => {
    //     dispatch(openRegister());
    //   }, 1000);
    // }
  }, []);

  useEffect(() => {
    if (siteSettings?.config_analytics) {
      const _ga = siteSettings?.config_analytics?.split(',');

      // Multiple products (previously known as trackers)
      ReactGA.initialize(
        _ga.map((item) => ({
          // measurement ID
          trackingId: item,
          // gaOptions: {...}, // optional
          // gtagOptions: {...}, // optional
        }))
        //   [
        //   {
        //     // measurement ID
        //     trackingId: _ga[0],
        //     // gaOptions: {...}, // optional
        //     // gtagOptions: {...}, // optional
        //   },
        // ]
      );
    }

    if (siteSettings?.config_livechat) {
      const respondIo = JSON.parse(siteSettings?.config_livechat);
      const script = document.createElement('script');

      script.id = respondIo?.id;
      script.src = respondIo?.src;
      script.async = true;

      document.head.appendChild(script);
    }

    if (siteSettings?.config_fb_pixel) {
      const metaPixel = JSON.parse(siteSettings?.config_fb_pixel);
      const options = {
        autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
        debug: false, // enable logs
      };

      if (Array.isArray(metaPixel)) {
        metaPixel.forEach((_pixel) => {
          ReactPixel.init(_pixel, options);

          ReactPixel.pageView(); // For tracking page view
        });
      } else {
        ReactPixel.init(metaPixel, options);

        ReactPixel.pageView(); // For tracking page view
      }
    }
  }, [siteSettings]);

  useEffect(() => {
    if (isAuthenticated && isVisible) {
      getUserMemberBalance(true);

      getProfile();
    }
  }, [isVisible, isAuthenticated]);

  useEffect(() => {
    const selector = '#_68625 > div > iframe';

    let observer;

    function handleMutation(mutations) {
      if (document.querySelector(selector)) {
        observer?.disconnect();
        setElement(document.querySelector(selector));
      }
    }

    if (document.querySelector(selector)) {
      setElement(document.querySelector(selector));
    } else {
      const observer = new MutationObserver(handleMutation);
      observer?.observe(document.body, {
        childList: true,
        subtree: true,
      });

      return () => {
        observer?.disconnect(); // Cleanup when the component unmounts
      };
    }
  }, []); // The empty dependency array ensures this effect runs only once

  useEffect(() => {
    if (element) {
      console.log('iframe Element is ready');
      element.style.opacity = 0;

      // it seems their js sets the bottom position with a slight delay after load, so we wait 1 sec then only set our css
      setTimeout(() => {
        const _widgetState = element?.getAttribute('state');
        updatePos(element, width, _widgetState);

        // observe attribute change
        const observer = new MutationObserver((mutations) => {
          mutations.forEach((mutation) => {
            if (mutation.type === 'attributes') {
              const widgetState = element?.getAttribute('state');

              console.log(`widgetState = ${widgetState}`);

              console.log('attributes changed');
              // set the css again
              updatePos(element, width, widgetState);
            }
          });
        });

        observer.observe(element, {
          attributes: true, // configure it to listen to attribute changes
        });
      }, 2000);
    }
  }, [element]);

  return (
    <Box
      sx={{
        display: { md: 'flex', lg: 'flex' },
        minHeight: { lg: 1 },
      }}
    >
      {process.env.REACT_APP_IS_DEV && (
        <Box
          sx={{
            position: 'fixed',
            left: 0,
            width: '80px',
            height: '80px',
            top: 0,
            overflow: 'hidden',
            borderRadius: '0px',
            zIndex: 99999,
          }}
        >
          <Box sx={{ transform: 'rotateX(0deg) rotateY(180deg) rotate(0deg)' }}>
            <Box
              sx={{
                //   borderImage: 'linear-gradient(to bottom, red, rgba(0, 0, 0, 0)) 1 100%',
                borderRight: '80px solid #d60000',
                borderBottom: '80px solid transparent',
                position: 'relative',
                top: '-20px',
                zIndex: 1,
              }}
            />
          </Box>
        </Box>
      )}
      <MobileNav />

      {/* <Typography sx={{ position: 'absolute', color: 'white', top: 1, right: 0 }}>{wifiSpeed}</Typography>

      <Box
      // sx={{ visibility: 'hidden', height: 0 }}
      >
        <ReactInternetSpeedMeter
          txtSubHeading="Internet is too slow"
          outputType="alert"
          customClassName={null}
          txtMainHeading="Opps..."
          pingInterval={4000} // milliseconds
          thresholdUnit="megabyte" // "byte" , "kilobyte", "megabyte"
          threshold={100}
          imageUrl="https://zk7web.s3.ap-south-1.amazonaws.com/home/loading-animation.webp"
          downloadSize="1741298" // bytes
          callbackFunctionOnNetworkDown={(speed) => console.log(`Internet speed is down ${speed}`)}
          callbackFunctionOnNetworkTest={(speed) => setWifiSpeed(speed)}
        />
      </Box> */}

      <DashboardHeader isCollapse={isCollapse} onOpenSidebar={() => setOpen(true)} />

      <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />

      <MainStyle>
        <Box
          sx={{
            backgroundImage: isSm ? 'none' : `url("${S3_ASSETS_URL}home/Bg.webp")`,
            backgroundPosition: 'top center',
            backgroundRepeat: 'repeat-y',
            backgroundSize: 'contain',
            height: '100%',
            width: '100%',
            top: 0,
            left: 0,
            position: 'absolute',
            opacity: 0.65,
            zIndex: -1,
          }}
        />
        <Outlet />
      </MainStyle>

      <CustomModal maxWidth={'xs'} open={isOpenLogin} onClose={() => dispatch(closeLogin())} children={<Login />} />
      <CustomModal
        maxWidth={'xs'}
        open={isOpenRegister}
        onClose={() => dispatch(closeRegister())}
        children={<Register />}
      />
      <CustomModal
        maxWidth={'xs'}
        open={isOpenForgotPwd}
        onClose={() => dispatch(closeForgotPwd())}
        children={<ForgotPassword />}
      />
      <CustomModal
        maxWidth={'xs'}
        open={isOpenChangePwd}
        onClose={() => dispatch(closeChangePwd())}
        children={<ChangePassword />}
      />

      {isOpenTransactionsDialog.open && <DepositWithdrawDialog />}

      {isAddMemberBankDialog?.open && <WithdrawFormDialog />}
    </Box>
  );
};

export default DashboardLayout;
